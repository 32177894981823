import { Setter } from "../../services/ComService";
import { DisplayScope, EditFieldKind, GenericEditField } from "./GenericDTO";
import ItemDTO, { IItem } from "./ItemDTO";

export default class ItemLocationDTO extends ItemDTO {
  eventId: number = -1
  constructor(o: IItemLocation) {
    super(o)
    this.srcAdd = 'spt/location/add'
    this.srcGet = 'spt/location/get'
    this.srcAddProp = 'spt/location/prop/add'
    this.srcPatchProp = 'spt/location/prop/patch'
    this.srcDelProp = 'spt/location/prop/del'
    this.eventId = o.eventId || -1
    this.editFields = LocationEditFields
    if (this.eventId > -1) {
      this.id = -2
      this.srcGet = 'spt/event/getLocation'
      this.optionsGet = {
        id: this.eventId
      }
    }
  }

  public async addEvent(eventId: number) {
    await Setter('spt/event/addLocation', {
      id: eventId,
      locationId: this.id,
    })
  }
}

export interface IItemLocation extends IItem {
  eventId?: number,
}

const LocationEditFields: GenericEditField[] = [
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Straße',
    key1: 'data',
    key2: 'street',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Hausnummer',
    key1: 'data',
    key2: 'streetNr',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Postleitzahl',
    key1: 'data',
    key2: 'postalcode',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Stadt',
    key1: 'data',
    key2: 'town',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Telefonnummer',
    key1: 'data',
    key2: 'phone',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Url',
    key1: 'data',
    key2: 'url',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
]
