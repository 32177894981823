import { useEffect, useState } from "react";
import mainservice from "../../../services/MainService";
import './EventEdit.scss'
import { EventDTO } from "../../DTO/EventDTO";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import { EditField } from "../EditField";
import { parseStringToServer } from "../../../services/TextConverter";
import PlaningToolRoot, { State } from "../PlaningToolRoot";
import { ObjectKind } from "../../models/objectKinds";
import DaysView from "../day/daysView";
import { DayDTO } from "../../DTO/DayDTO";
import { SeminarDayDTO } from "../../DTO/SeminarDayDTO";
import ModuleDTO from "../../DTO/ModuleDTO";
import DayModuleDTO from "../../DTO/DayModuleDTO";
import { GenericDTO } from "../../DTO/GenericDTO";
import MaterialsView from "../materialsView";
import { PublishKind } from "../../models/publishKinds";
import { Printer } from "react-feather";
import { MItem } from "../Menu";
import { BuildingBlocks } from "./BuildingBlocks";
import { EditItem } from "./EditItem";
import { SeminarDTO } from "../../DTO/SeminarDTO";
import { PlaningDetails } from "./PlaningDetails";
import H2Edit from "../../elements/H2Edit";
import PersonList from "../Person/PersonList";
import { PersonKind } from "../Person/PersonKind";
import { RightDrawerContent } from "../RightDrawer";
import FotoprotokollView from "../fotoprotokollView";

enum LeftTab {
  details,
  info,
  days,
  materiallist,
  fotoprotokoll,
  personlist,
}

enum Env {
  none = 0,
  design = 1,
  planing = 2,
  all = 3,
}

const LeftTabs = [
  {
    id: LeftTab.details,
    name: 'Planung',
    env: Env.all,
  },
  {
    id: LeftTab.info,
    name: 'Konzept',
    env: Env.all,
  },
  {
    id: LeftTab.days,
    name: 'Ablauf',
    env: Env.all,
  },
  {
    id: LeftTab.materiallist,
    name: 'Packliste',
    env: Env.planing,
  },
  {
    id: LeftTab.fotoprotokoll,
    name: 'Fotoprotokoll',
    env: Env.planing,
  },
  {
    id: LeftTab.personlist,
    name: 'TN-Info',
    env: Env.planing,
  },
]

export default function EventEdit(props: {
  isTemplate?: boolean,
}) {
  const id = mainservice.nav.getVal('id')
  const [Event] = useState(props.isTemplate === true ? new SeminarDTO({id: mainservice.nav.getVal('id') as string || ''}) : new EventDTO({id: id as string || ''}))
  const page = mainservice.nav.getVal('page') || (props.isTemplate === true ? LeftTab.info : LeftTab.details)
  const [status, setStatus] = useState(Status.loading)
  const [dayStatus, setDayStatus] = useState(Status.loading)
  const [currentLeftTab, setCurrentLeftTab] = useState(page)
  const [rerender, setRerender] = useState(0)
  const [editItem, setEditItem] = useState(new GenericDTO({id: -1}))
  const [leftDrawerBroadcastKey, setLeftDrawerBroadcastKey] = useState('')
  const [editObjectKind, setEditObjectKind] = useState(-1)
  const [showLeftDrawer, setShowLeftDrawer] = useState(false)
  const [seminarName, setSeminarName] = useState(Event.name)
  const [reloadRightSide, setReloadRightSide] = useState(1)
  const [currentActiveDay, setCurrentActiveDay] = useState(-Status.dayView)
  const broadCastBackKeyReload = `EventReload-${Event.id}`

  function init () {
    Event.getFromServer().then((d) => {
      setSeminarName(Event.name)
      if (d instanceof SeminarDTO) {
        d.getChildrenFromServer().then(() => {
          setSeminarName(d.name)
          setRerender(new Date().getTime())
          setStatus(Status.standard)
        })
      } else {
        setStatus(Status.standard)
      }
    }).catch((err) => {
      console.log('err', err)
    })
  }
  useEffect(() => {
    init()
  }, [])

  mainservice.registerToBroadcast(`EventEdit-${Event.id}`,
    async (key: string, _value: any) => {
      const backData = _value as {position: number, item: ModuleDTO, type: 'time' | 'position' | 'template2seminar' | 'duration'}
      if (key === broadCastBackKeyReload) {
        // const oldStatus = status
        setStatus(Status.loading)
        await Event.getFromServer()
        setStatus(Status.standard)
      }
    }
  )
  
  // Select the right Menu item:
  let menuItem = MItem.planer
  if (Event.deletedDate) {
    menuItem = MItem.trash
  } else if (Event.getTagsByKind('contractState')[0]?.special?.search('leads') > -1) {
    menuItem = MItem.leads
  } else if (props.isTemplate === true) {
    menuItem = MItem.designer
  }

  return <PlaningToolRoot
    standalone={true}
    menuItem={menuItem}
    mainContentClass={`flex flex-column mh100noScroll ${Event.deletedDate ? 'w3-border w3-border-red' : ''}`}
    topMenu={
      LeftTabs.filter(lt => !props.isTemplate || (lt.id !== LeftTab.details && lt.id !== LeftTab.personlist && lt.id !== LeftTab.fotoprotokoll)).map((lt, index) => {
        return <button
            key={`left-tab-${lt.id}`}
            className={`tab-button ${lt.id === currentLeftTab ? 'tab-active' : ''}`}
            onClick={() => {
              setCurrentLeftTab(lt.id)
              mainservice.nav.setPage(lt.id)
            }}
          >
            {lt.name}
          </button>
      })
    }
    rightDrawer={(currentLeftTab !== LeftTab.days && currentLeftTab !== LeftTab.info) ? undefined : <BuildingBlocks
      editItem={(item: any, objectKind: ObjectKind) => {
        setEditItem(item)
        if (editObjectKind !== objectKind) {
          setEditObjectKind(objectKind)
        }
        setShowLeftDrawer(true)
      }}
      reloadTrigger={reloadRightSide}
    />}
    startStateRightDrawer={(currentLeftTab !== LeftTab.days && currentLeftTab !== LeftTab.info) ? State.closed : State.open}
    showLeftDrawer={showLeftDrawer}
    leftDrawer={
      editItem ?
        <EditItem
          close={() => {
            setReloadRightSide(reloadRightSide + 1)
            setShowLeftDrawer(false)
            setTimeout(() => {
              setEditItem(new GenericDTO({id: -1}))
            }, 300)
          }}
          item={editItem}
          objectKind={editObjectKind}
          rerenderRightSide={() => {
            setReloadRightSide(reloadRightSide + 1)
          }}
          broadcastKey={leftDrawerBroadcastKey}
        /> : undefined
    }
  >
    

    <div className='contentHeader'>
      <div className='flex justify-content-space-between w100'>
        <H2Edit
          value={Event.getName()}
          onChange={async (newValue) => {
            await Event.setName(newValue)
            setRerender(rerender + 1)
          }}
          oldValue={Event.getUpName(true)}
          resetToOldValue={async () => {
            await Event.setName('')
            setRerender(rerender + 1)
          }}
        />
        <div>
          <button
            className='neutralButton'
            onClick={() => {
              // var element = document.getElementById('event-print');
              var element = document.getElementsByClassName('PlaningToolRootMainContentHolder')[0]
              var opt = {
                margin:       20,
                filename:     Event.name + '.pdf',
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 1 },
                jsPDF:        { unit: 'mm', orientation: 'portrait', format: 'a4' },
              };
              // @ts-ignore
              html2pdf().from(element).set(opt).save();
            }}
          ><Printer /></button>
        </div>
      </div>
    </div>
    { status === Status.loading ? <Spinner /> :
      <div className='flex-grow' id='event-print'>
        { currentLeftTab === LeftTab.details && Event instanceof EventDTO &&
          <PlaningDetails event={Event} />
        }
        { currentLeftTab === LeftTab.days &&
          <DaysView
            identKey='leftSide'
            items={Event.getDays()}
            getItems={async () => {
              await Event.getChildrenFromServer()
              return Event.getDays()
            }}
            parent={Event}
            rerender={rerender}
            addItem={async (position: number, item: DayDTO) => {
              await Event.addDay(position, item)
              setRerender(new Date().getTime())
            }}
            trashItem={async (day: SeminarDayDTO) => {
              await Event.trashDay(day)
              setRerender(new Date().getTime())
            }}
            setDayStatus={setDayStatus}
            dayStatus={dayStatus}
            parentObjectKind={Event.objectKind}
            parentPublishKind={Event.publishKind}
            rerenderRightSide={() => {
            }}
            setEditItem={(module: ModuleDTO | DayModuleDTO, broadcastKey?: string) => {
              console.log('Init Loading by setEditItem 2')
              if (broadcastKey !== undefined) {
                setLeftDrawerBroadcastKey(broadcastKey)
              }
              setEditItem(module)
              setEditObjectKind(module.objectKind)
              setShowLeftDrawer(true)
            }}
            currentActiveDay={currentActiveDay}
            setCurrentActiveDay={setCurrentActiveDay}
          />
        }
        { currentLeftTab === LeftTab.materiallist &&
          <>
            <MaterialsView
              items={[]}
              identKey={`seminar-${Event.id}`}
              parentPublishKind={PublishKind.isSeminar}
              parentObjectKind={ObjectKind.seminar}
              getItems={async () => {
                return await Event.getMaterialList()
              }}
              displayKind={Status.list}
              parent={Event}
              checklists={[
                {
                  title: 'Gepackt',
                  key1: 'checklist-packed',
                }
              ]}
            />
          </>
        }
        { currentLeftTab === LeftTab.fotoprotokoll &&
          <>
            <FotoprotokollView
              items={[]}
              identKey={`seminar-${Event.id}`}
              parentPublishKind={PublishKind.isSeminar}
              parentObjectKind={ObjectKind.seminar}
              getItems={async () => {
                return await Event.getMaterialList()
              }}
              displayKind={Status.list}
              parent={Event}
              checklists={[
                {
                  title: 'Gepackt',
                  key1: 'checklist-packed',
                }
              ]}
            />
          </>
        }
        {
          currentLeftTab === LeftTab.personlist &&
          <PersonList
            eventId={Event.id}
            personKind={PersonKind.generic}
            headline={'Teilnehmer'}
            noSearch={true}
            compact={'joinedEvents'}
            className={'joinedEvents'}
          />
        }
        { currentLeftTab === LeftTab.info &&
          <div className='contentPart w3-padding'>
            {
              Event.getEditFields().map((ef, index) => {
                return <EditField
                    key={`ef-${index}`}
                    field={ef}
                    patch={async (efp) => {
                      await Event.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''))
                      return efp
                    }}
                    item={Event}
                    editMode={true}
                  />
              })
            }
            <h3>Assets</h3>
            <MaterialsView
              items={Event.getMaterialItems()}
              getItems={async () => {
                await Event.getChildrenFromServer()
                return Event.getMaterialItems()
              }}
              identKey={`seminar-view-${Event.id}-`}
              parentObjectKind={Event.objectKind}
              parentPublishKind={Event.publishKind}
              addItem={async (position, item) => {
                await Event.addMaterialItem(position, item)
                setRerender(new Date().getTime())
              }}
              displayKind={Status.edit}
              trashItem={async (item) => {
                await Event.trashMaterialItem(item)
                setRerender(new Date().getTime())
              }}
              reloadTrigger={rerender}
              editItem={(item: GenericDTO, objectKind: ObjectKind) => {
                mainservice.broadcast('rightDrawerOpen', {
                  id: item.id,
                  broadCastBackKey: broadCastBackKeyReload,
                  contentType: RightDrawerContent.editMaterial
                })
              }}
            />
          </div>
        }
      </div>
    }
  </PlaningToolRoot>
}



