import { useEffect, useState } from "react";
import { MItem } from "../Menu";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import ItemLocationDTO from "../../DTO/ItemLocationDTO";
import mainservice from "../../../services/MainService";
import { EditField } from "../EditField";
import { parseStringFromServer, parseStringToServer } from "../../../services/TextConverter";
import EventList from "../Event/EventList";
import { Edit } from "react-feather";
import InputA from "../../../components/InputA/InputA";
import PlaningToolRoot from "../PlaningToolRoot";
import { RightDrawerContent } from "../RightDrawer";
import BroadcastRightButton from "../../elements/BroadcastRightButton";
import { PublishKind } from "../../models/publishKinds";

export default function LocationEdit(props: {

}) {
  const id = mainservice.nav.getVal('id') as number
  const [status, setStatus] = useState(Status.loading)
  const [RerenderEvents, setRerenderEvents] = useState(0)
  const [EditId, setEditId] = useState(-1)
  const [Item, setItem] = useState(new ItemLocationDTO({
    id: id || -1
  }))
  const [UserMsg, setUserMsg] = useState('')
  const broadCastBackKeyEventAdd = `CustomerEventAddFor-${Item.id}`
  mainservice.registerToBroadcast(`CustomerEdit-${Item.id}`,
    async (key: string, _value: any) => {
      if (key === broadCastBackKeyEventAdd) {
        setStatus(Status.loading)
        await Item.addEvent(_value.id as number)
        setStatus(Status.standard)
      }
    }
  )
  function init () {
    Item.getFromServer().then(() => {
      setStatus(Status.standard)
    }).catch((err) => {
      console.log('err', err)
      setUserMsg(err.description)
    })
  }
  useEffect(() => {
    init()
  }, [])
  function WarnMsg() {
    return <>
      {
      UserMsg &&
      <div
        className={`w3-border w3-border-yellow w3-padding`}
      >
        {UserMsg}
        <button
          onClick={() => {
            setUserMsg('')
          }}
          className={`w3-button w3-grey`}
        >OK</button>
      </div>
    }
    </>
  }
  return <PlaningToolRoot standalone={true} menuItem={MItem.locations} topMenu={<h2>Veranstaltungsort - {Item.name}</h2>}>
    <div className='STLocationEdit'>
      <div className='contentHeader'>
        <h1>Veranstaltungsort - {Item.name}</h1>
      </div>
      <WarnMsg />
      {
        status === Status.loading &&
        <Spinner />
      }
      {
        status !== Status.loading &&
        <>
          <div className='contentPart'>
            <div
              className={`w3-border-top w3-margin-bottom w3-padding-top w3-container`}
            >
              <div className='bold'>Name</div>
              {
                EditId !== -2 &&
                <>
                  <button
                    className={`floatRight w3-button`}
                    onClick={() => {
                      setEditId(-2)
                    }}
                  ><Edit /></button>
                  <div dangerouslySetInnerHTML={{__html: Item.name}}></div>
                </>
              }
              {
                EditId === -2 &&
                <InputA
                  value={parseStringFromServer(Item.name || '')}
                  returnVal={async (rv) => {
                    await Item.setName(rv)
                    setEditId(-1)
                  }}
                />
              }
            </div>
            <div className='w3-container w3-border-top'>
              {
                Item.getEditFields().map((ef, index) => {
                  return <EditField
                    key={`ef-${index}`}
                    field={ef}
                    patch={async (efp) => {
                      await Item.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''))
                      return efp
                    }}
                    item={Item}
                    editMode={true}
                  />
                })
              }
            </div>
            </div>
          <div className='contentPart'>
            <EventList
              locationId={Item.id}
              rerenderTrigger={RerenderEvents}
              noNew={true}
              allowRemove={true}
              headline={'Veranstaltungen'}
              publishKind={PublishKind.isSeminar}
              ignoreDate='ignore'
            />
            <BroadcastRightButton
              contentType={RightDrawerContent.selectEventForLocation}
              broadCastBackKey={broadCastBackKeyEventAdd}
              title={`Weitere Veranstaltung zuordnen`}
            />
          </div>
        </>
        
      }

    </div>
  </PlaningToolRoot>
}
