import { Setter } from "../../services/ComService";
import { DisplayScope, EditFieldKind, GenericEditField } from "./GenericDTO";
import ItemDTO, { IItem } from "./ItemDTO";

export default class ItemCustomerDTO extends ItemDTO {
  eventId: number = -1
  personId: number = -1
  parentId: number = -1
  parentName: string = ''
  constructor(o: IItemCustomer) {
    super(o)
    this.srcAdd = 'spt/customer/add'
    this.srcPatch = 'spt/customer/patch'
    this.srcGet = 'spt/customer/get'
    this.srcAddProp = 'spt/customer/prop/add'
    this.srcPatchProp = 'spt/customer/prop/patch'
    this.srcDelProp = 'spt/customer/prop/del'
    this.eventId = o.eventId || -1
    this.personId = o.personId || -1
    this.editFields = CustomerEditFields
    if (this.eventId > -1) {
      this.id = -2
      this.srcGet = 'spt/event/getCustomer'
      this.optionsGet = {
        id: this.eventId
      }
    } else if (this.personId > -1) {
      this.id = -2
      this.srcGet = 'spt/person/getCustomer'
      this.optionsGet = {
        id: this.personId
      }
    }
    this.parentId = o.parentId || -1
    this.parentName = o.parentName || ''
    
  }

  afterInit(o: any) {
    this.parentId = o.parentId || -1
    this.parentName = o.parentName || ''
    console.log('Customer done', this, this.parentId, o)
  }

  public async addPerson(personId: number) {
    await Setter('spt/person/addCustomer', {
      id: personId,
      customerId: this.id,
    })
  }

  public async removePerson(personId: number) {
    await Setter('spt/person/removeCustomer', {
      id: personId,
      customerId: this.id,
    })
  }

  public async addCustomerLocation(customerLocationId: number) {
    await Setter('spt/customer/addLocation', {
      id: this.id,
      locationId: customerLocationId,
    })
  }

  public async addAsCustomerLocation(parentId: number) {
    const result = await Setter('spt/customer/addLocation', {
      id: parentId,
      locationId: this.id,
    })
    this.init(result.item)
  }

  public async unlinkParent() {
    await Setter('spt/customer/unlinkParent', {
      id: this.id,
    })
    this.parentId = -1
    this.parentName = ''
  }

  public async unlinkLocation(id: number) {
    await Setter('spt/customer/unlinkParent', {
      id: id,
    })

  }

  public async addEvent(eventId: number) {
    await Setter('spt/event/addCustomer', {
      id: eventId,
      customerId: this.id,
    })
  }
}

export interface IItemCustomer extends IItem {
  eventId?: number,
  personId?: number,
  parentId?: number,
  parentName?: string,
}

const CustomerEditFields: GenericEditField[] = [
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Straße',
    key1: 'data',
    key2: 'street',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Hausnummer',
    key1: 'data',
    key2: 'streetNr',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Postleitzahl',
    key1: 'data',
    key2: 'postalcode',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Stadt',
    key1: 'data',
    key2: 'town',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Telefonnummer',
    key1: 'data',
    key2: 'phone',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
  {
    title: 'Url',
    key1: 'data',
    key2: 'url',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.string,
  },
]
