import { Setter } from "../../services/ComService";
import { ObjectKind } from "../models/objectKinds";
import { PublishKind } from "../models/publishKinds";
import { DayDTO, IDay } from "./DayDTO";
import { ISeminar, SeminarDTO } from "./SeminarDTO";
import { ITrainingItem, TrainingItemDTO } from "./TrainingItemDTO";
import ModuleDTO, { IModule } from "./ModuleDTO";
import { ISeminarDay, SeminarDayDTO } from "./SeminarDayDTO";
import DayModuleDTO, { IDayModule } from "./DayModuleDTO";
import { IMaterialItem, MaterialItemDTO } from "./MaterialItemDTO";
import { IProp } from "./PropDTO";
import { EventDTO, IEvent } from "./EventDTO";
import { GenericDTO } from "./GenericDTO";
import { DayDiff, DisplayDateTimeNumeric } from "../../services/DateTime";
import { PersonKind } from "../views/Person/PersonKind";

type Item = EventDTO | SeminarDTO | SeminarDayDTO | DayDTO | DayModuleDTO | ModuleDTO | TrainingItemDTO | MaterialItemDTO
type IItem = IEvent | ISeminar | ISeminarDay | IDay | IDayModule | IModule | ITrainingItem | IMaterialItem
export default class BuildingBlocksDTO {
  elementKindPage: ObjectKind = ObjectKind.none
  currentPublishKind?: PublishKind = PublishKind.isTemplate
  showItems: Item[] = []

  customerId: number = -1
  customerLocationId: number = -1
  locationId: number = -1
  personId: number = -1

  tagIdString: string = ''

  minDate: string = ''

  onlyWithoutCustomer?: boolean = false
  onlyWithoutCustomerLocation?: boolean = false
  onlyWithoutLocation?: boolean = false
  personKind: PersonKind = PersonKind.generic
  compact: boolean | 'name' | 'participated' = false
  leads: string = ''
  props?: IProp[] = []
  needle?: string = ''
  deleted: string = '0'
  showEmptyElement: boolean = true
  ignoreDate?: '' | 'ignore' = ''

  constructor(o?: {
    customerId?: number,
    customerLocationId?: number,
    locationId?: number,
    personId?: number,
    onlyWithoutCustomer?: boolean,
    onlyWithoutCustomerLocation?: boolean,
    onlyWithoutLocation?: boolean,
    personKind?: PersonKind,
    compact?: boolean | 'name' | 'participated',
    leads?: string,
    elementKindPage?: ObjectKind,
    mode?: 'normal' | 'trash',
    showEmptyElement?: boolean,
    minDate?: string,
    publishKind?: PublishKind,
    ignoreDate?: '' | 'ignore',
  }) {
    if (o?.publishKind !== undefined) {
      this.currentPublishKind = o.publishKind
      console.log('BuildingBlocks publishKind: ', PublishKind[this.currentPublishKind])
    }
    if (o?.ignoreDate) {
      this.ignoreDate = o.ignoreDate
    }
    if (o?.showEmptyElement !== undefined) {
      this.showEmptyElement = o.showEmptyElement
    }
    if (o?.customerId !== undefined) {
      this.customerId = o.customerId
    }
    if (o?.customerLocationId !== undefined) {
      this.customerLocationId = o.customerLocationId
    }
    if (o?.locationId !== undefined) {
      this.locationId = o.locationId
    }
    if (o?.personId !== undefined) {
      this.personId = o.personId
    }
    if (o?.personKind) {
      this.personKind = o.personKind
    }
    // Set Flag for server, so that it will only get as deleted marked entries:
    if (o?.mode === 'trash') {
      this.deleted = '1'
    }
    if (o?.minDate) {
      this.minDate = o.minDate
    }
    this.leads = o?.leads || ''
    this.compact = o?.compact || false
    this.onlyWithoutCustomer = !!o?.onlyWithoutCustomer
    this.onlyWithoutCustomerLocation = !!o?.onlyWithoutCustomerLocation
    this.onlyWithoutLocation = !!o?.onlyWithoutLocation
    if (o?.elementKindPage) {
      this.elementKindPage = o.elementKindPage
    }
  }

  public ShowItems() {
    return this.showItems
  }

  public ShowEvents() {
    let events = this.showItems as EventDTO[]
    let out: EventDTO[] = []
    events.forEach(e => {
      let appointments = e.getAppointments()
      let appointmentAmount = appointments.length
      if (appointmentAmount > 1) {
        appointments.forEach((a, index) => {
          const eventItem = {...e, ...{
            startDate: {
              date: a.getV1()
            },
            endDate: {
              date: a.getV2()
            },
            titleSuffix: `(${index + 1}/${appointmentAmount})`,
            appointmentKey: 'tag-' + a.key2,
          }}
          console.log('eventItem', eventItem)
          out.push(new EventDTO(eventItem))
        })
      } else {
        out.push(e)
      }
    })
    // console.log('showEvents', out)
    return out.sort((a,b) => {
      const asv = a.getStartString()
      const bsv = b.getStartString()
      if (asv < bsv) {
        return -1
      }
      return 1
    })
  }

  public async reload() {
    await this.setElementKindPage(0, this.elementKindPage, this.currentPublishKind)
  }

  public concatElements(page: number, item: Item, items: Item[]): Item[] {
    if (page === 0) {
      return [item, ...items]
    }
    return items
  }

  public async setElementKindPage(page: number, key?: ObjectKind, publishKind?: PublishKind, needle?: string, props?: IProp[]) {
    if (props === undefined) {
      props = this.props
    } else {
      this.props = props
    }
    // console.log('needle', needle, this.needle)
    if (needle === undefined) {
      needle = this.needle
    } else {
      this.needle = needle
    }
    // console.log('needle2', needle, this.needle)
    this.elementKindPage = (key === undefined) ? this.elementKindPage : key
    this.currentPublishKind = publishKind
    const kind = (publishKind === undefined) ? PublishKind.isTemplate : publishKind
    const items = await this.getElementsFromServer(page, this.elementKindPage, kind, needle, props)
    console.log('we arrived buildingBlocks items', items)
    let showItems: Item[] = []
    switch (this.elementKindPage) {
      case ObjectKind.event:
        showItems = items.map((item: IEvent) => new EventDTO(item))
        break
      case ObjectKind.seminar:
        showItems = items.map((day: ISeminar) => new SeminarDTO(day))
        break
      case ObjectKind.day:
        showItems = this.concatElements((this.showEmptyElement ? page : -1), // If page is 0 empty element is beeing added. In Bausteineditor we never want to do that
          new DayDTO({
            id: -1,
            name: 'leerer Tag',
          }),
          items.map((day: IDay) => new DayDTO(day))
        )
        break
      case ObjectKind.module:
        showItems = this.concatElements((this.showEmptyElement ? page : -1),
          new ModuleDTO({
            id: -1,
            name: 'leere Sequenz',
            duration: 60,
            publishKind: PublishKind.isTemplate,
          }),
          items.map((item: IModule) => new ModuleDTO(item))
        )
        break
      case ObjectKind.trainingItem:
        showItems = this.concatElements((this.showEmptyElement ? page : -1),
          new TrainingItemDTO({
            id: -1,
            name: 'leere Kollektion'
          }),
          items.map((item: ITrainingItem) => new TrainingItemDTO(item))
        )
        break
      case ObjectKind.materialItem:
        showItems = this.concatElements((this.showEmptyElement ? page : -1),
          new MaterialItemDTO({
            id: -1,
            name: 'Individuelles Material'
          }),
          items.map((item: IModule) => new MaterialItemDTO(item))
        )
        break
      default:
        console.log('this did not work!!!', this.elementKindPage)
        break
    }
    if (page === 0) {
      this.showItems = showItems
      return
    }
    // If page is greater then 0 -> concat more items!
    this.showItems = [...this.showItems, ...showItems]
    return showItems.length
  }

  public extractEventIdFromNeedle(s: string): { needle: string, flatProps: string[] } {
    let needle: string = s
    let props: string[] = []
    const eventIdScope = /#([0-9]+) ?/
    const eventIdMatch = s.match(eventIdScope)
    if (eventIdMatch !== null) {
      props.push(`data,uniqueEventId,${eventIdMatch[1]},,start`)
      needle = needle.replace(eventIdScope, '')
    }
    return {
      needle: needle,
      flatProps: props,
    }
  }

  public async getElementsFromServer(page: number, objectKind: ObjectKind, publishKind: PublishKind, needle?: string, props?: IProp[]) {
    const url = 'spt/getList'
    const needleResult = this.extractEventIdFromNeedle(needle || '')
    let flatPropsArray = needleResult.flatProps
    if (props) {
      flatPropsArray = flatPropsArray.concat(props.map(p => [p.key1, p.key2, p.value1].join(',')))
    }
    const flatProps = flatPropsArray.join(';')
    console.log('BuildingBlocks get Elements from Server. publishKind', PublishKind[publishKind])
    const query = {
      publishKind: publishKind,
      objectKind: objectKind,
      customerId: this.customerId,
      customerLocationId: this.customerLocationId,
      locationId: this.locationId,
      personId: this.personId,
      needle: needleResult.needle,
      props: flatProps,
      minDate: this.ignoreDate || this.minDate,
      onlyWithoutCustomer: this.onlyWithoutCustomer,
      onlyWithoutCustomerLocation: this.onlyWithoutCustomerLocation,
      onlyWithoutLocation: this.onlyWithoutLocation,
      personKind: PersonKind[this.personKind],
      tags: this.tagIdString,
      compact: this.compact,
      leads: this.leads,
      page: page,
      deleted: this.deleted,
    }
    const data = await Setter(url, query)
    console.log('we arrived data with publishKind', PublishKind[publishKind], query, data)
    try {
      return (data as { items: IItem[] }).items
    } catch (error) {
      console.error('problem with datasource ', url, query)
      return []
    }
  }

  public removeItem(id: number) {
    this.showItems = this.showItems.filter(i => i.id !== id)
  }

  public async cloneItem(id: number, objectKind: ObjectKind, publishKind?: PublishKind) {
    const tI = this.showItems.find(i => i.id === id)
    if (!tI) { return }
    const now = new Date()
    const dateString = DisplayDateTimeNumeric(now)
    await tI.clone(-1, objectKind, tI.name + ' (Kopie ' + dateString + ')', publishKind)
    await this.reload()
  }

  public prepareGantt(items: Item[]) {
    let minDate = this.minDate
    let maxDate = this.minDate
    items.forEach(i => {
      if (i.enddate && i.enddate > maxDate) {
        maxDate = i.enddate
      }
      if (i.startdate && i.startdate < minDate) {
        minDate = i.startdate
      }
    })
    // now we got minDate and maxDate
    // now we transform these data to days
    const min = new Date(minDate)
    const max = new Date(maxDate)
    const diffDays = DayDiff(min, max)
    // now we get the position of each element:
    return {
      min: min,
      max: max,
      diffDays: diffDays,
      items: items.map(i => {
        let cellsBefore = 0
        let cellsAfter = 0
        let span = diffDays + 0
        if (i.startdate) {
          cellsBefore = DayDiff(min, new Date(i.startdate))
          span -= cellsBefore
        }
        if (i.enddate) {
          cellsAfter = DayDiff(max, new Date(i.enddate))
          span -= cellsAfter
        }
        return {
          id: i.id,
          cellsBefore: cellsBefore,
          cellsAfter: cellsAfter,
          span: span,
        }
      })
    }
  }


}
