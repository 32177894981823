import { useState } from 'react';
import mainservice from '../../services/MainService';
import { State } from './PlaningToolRoot';
import PersonList from './Person/PersonList';
import { PersonKind } from './Person/PersonKind';
import EventList from './Event/EventList';
import CustomerList from './Customer/CustomerList';
import CustomerLocationList from './CustomerLocation/CustomerLocationList';
import LocationList from './Location/LocationList';
import MaterialView from './materialView';
import { DisplayKind, MaterialItemDTO } from '../DTO/MaterialItemDTO';
import { DisplayMode } from './day/dayView';
import { ObjectKind } from '../models/objectKinds';
import { X } from 'react-feather';
import ItemPersonDTO from '../DTO/ItemPersonDTO';
import { PublishKind } from '../models/publishKinds';

export enum RightDrawerContent {
  nothing,
  selectPerson,
  selectEvent,
  selectEventForLocation,
  selectEventForCustomer,
  selectSeminar,
  selectCustomer,
  selectCustomerLocation,
  selectLocation,
  editMaterial,
  viewMaterial,
}

export function RightDrawer(props: {
  changeOpenState: (newState: State) => void,
  children?: React.ReactElement | (React.ReactElement | null | false)[],
}) {
  const [contentType, setContentType] = useState(RightDrawerContent.nothing)
  const [personKind, setPersonKind] = useState(PersonKind.generic)
  const [id, setId] = useState(-1)
  const [broadCastBackKey, setBroadCastBackKey] = useState('broadCastBackKey')
  const [materialItem, setMaterialItem] = useState(new MaterialItemDTO({id: -1}))
  mainservice.registerToBroadcast(
    'RightDrawer',
    (key: string, _value: any) => {
      if (key !== 'rightDrawerOpen') { return }
      console.log('RightDrawer', _value)
      if (_value.personKind) { setPersonKind(_value.personKind as PersonKind) }
      if (_value.broadCastBackKey) { setBroadCastBackKey(_value.broadCastBackKey as string) }
      if (_value.contentType === RightDrawerContent.editMaterial) {
        setId(_value.id)
      }
      if (_value.contentType === RightDrawerContent.viewMaterial) {
        setId(_value.id)
      }
      setContentType(_value.contentType as RightDrawerContent)
      props.changeOpenState(State.open)
    }
  )
  function showContent() {
    if (contentType === RightDrawerContent.selectPerson) {
      return <PersonList
        className='w3-margin-top'
        selected={-1}
        headline={'Personen'}
        onSelected={async (personIds: number[]) => {
          mainservice.broadcast(broadCastBackKey, {
            personIds: personIds,
          })
          setContentType(RightDrawerContent.nothing)
          props.changeOpenState(State.closed)
        }}
        personKind={personKind}
        compact={'medium'}
      />
    }
    if (contentType === RightDrawerContent.selectEvent) {
      return <EventList
        className='w3-margin-top'
        headline={'Veranstaltung wählen'}
        onSelect={async (id, item) => {
          mainservice.broadcast(broadCastBackKey, {
            id: id,
            name: item.name,
          })
          setContentType(RightDrawerContent.nothing)
          props.changeOpenState(State.closed)
        }}
        objectKind={ObjectKind.event}
        publishKind={PublishKind.isSeminar}
        compact={true}
        ignoreDate='ignore'
      />
    }
    if (contentType === RightDrawerContent.selectEventForCustomer) {
      return <EventList
        className='w3-margin-top'
        headline={'Veranstaltung wählen'}
        onSelect={async (id, item) => {
          mainservice.broadcast(broadCastBackKey, {
            id: id,
            name: item.name,
          })
          setContentType(RightDrawerContent.nothing)
          props.changeOpenState(State.closed)
        }}
        objectKind={ObjectKind.event}
        publishKind={PublishKind.isSeminar}
        compact={true}
        onlyWithoutCustomer={true}
        ignoreDate='ignore'
      />
    }
    if (contentType === RightDrawerContent.selectEventForLocation) {
      return <EventList
        className='w3-margin-top'
        headline={'Veranstaltung wählen'}
        onSelect={async (id, item) => {
          mainservice.broadcast(broadCastBackKey, {
            id: id,
            name: item.name,
          })
          setContentType(RightDrawerContent.nothing)
          props.changeOpenState(State.closed)
        }}
        objectKind={ObjectKind.event}
        publishKind={PublishKind.isSeminar}
        compact={true}
        onlyWithoutLocation={true}
        ignoreDate='ignore'
      />
    }
    if (contentType === RightDrawerContent.selectSeminar) {
      return <EventList
        className='w3-margin-top'
        headline={'Vorlage wählen'}
        onSelect={async (id, item) => {
          mainservice.broadcast(broadCastBackKey, {
            id: id,
            name: item.name,
          })
          setContentType(RightDrawerContent.nothing)
          props.changeOpenState(State.closed)
        }}
        objectKind={ObjectKind.seminar}
        publishKind={PublishKind.isTemplate}
        compact={true}
      />
    }
    if (contentType === RightDrawerContent.selectCustomer) {
      return <CustomerList
        className='w3-margin-top'
        headline={`Kunde wählen`}
        onSelect={async (id, item) => {
          mainservice.broadcast(broadCastBackKey, {
            id: id,
            name: item.name,
          })
          setContentType(RightDrawerContent.nothing)
          props.changeOpenState(State.closed)
        }}
      />
    }
    if (contentType === RightDrawerContent.selectCustomerLocation) {
      return <CustomerLocationList
        className='w3-margin-top'
        headline={`Kundenstandort wählen`}
        onSelect={async (id, item) => {
          mainservice.broadcast(broadCastBackKey, {
            id: id,
            name: item.name,
          })
          setContentType(RightDrawerContent.nothing)
          props.changeOpenState(State.closed)
        }}
      />
    }
    if (contentType === RightDrawerContent.selectLocation) {
      return <LocationList
        className='w3-margin-top'
        headline={`Veranstaltungsort wählen`}
        onSelect={async (id, item) => {
          mainservice.broadcast(broadCastBackKey, {
            id: id,
            name: item.name,
          })
          setContentType(RightDrawerContent.nothing)
          props.changeOpenState(State.closed)
        }}
      />
    }
    if (contentType === RightDrawerContent.editMaterial) {
      return <>
        <MaterialView
          materialItem={materialItem}
          displayMode={DisplayMode.edit}
          parentPublishKind={materialItem.publishKind}
          parentObjectKind={materialItem.objectKind}
          id={id}
        />
      </>
    }
    if (contentType === RightDrawerContent.viewMaterial) {
      return <>
        <MaterialView
          materialItem={materialItem}
          displayMode={DisplayMode.view}
          parentPublishKind={materialItem.publishKind}
          parentObjectKind={materialItem.objectKind}
          id={id}
        />
      </>
    }
    return null
  }
  const specialContent = showContent()
  if (specialContent !== null) {
    return <>
      <div>
        <button
          className='w3-button floatRight'
          onClick={() => {
            if (contentType === RightDrawerContent.viewMaterial || contentType === RightDrawerContent.selectEvent || contentType === RightDrawerContent.selectSeminar) {
              props.changeOpenState(State.closed)
            }
            setContentType(RightDrawerContent.nothing)
          }}
        ><X /></button>
      </div>
      {specialContent}
    </>
  }
  if (props.children) {
    return <>{props.children}</>
  }
  return null;
}
